<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.edit_product") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="formElements" class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="updateProduct"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";

export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const estates = computed(() => store.state.estate.estates);
    const product = computed(() => store.getters["product/getProduct"]);

    const waiting = computed(() => {
      if (store.state.estate.waiting) return true;
      if (store.state.product.waiting) return true;
      return false;
    });

    const formElements = ref(null);

    const setFormElements = () => {
      formElements.value = [
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "name",
          label: "name",
          rules: ["isRequired"],
          type: "text",
          value: product.value.name,
          variable: "name",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "textarea",
          id: "description",
          label: "description",
          rows: 5,
          rules: ["isRequired"],
          value: product.value.description,
          variable: "description",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "price",
          label: "price",
          rules: ["isRequired", "isNumber"],
          type: "text",
          value: (product.value.price / 100).toString(),
          variable: "price",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "estimated-delivery",
          label: "estimated_delivery",
          rules: ["isRequired"],
          type: "text",
          value: product.value.estimatedDelivery,
          variable: "estimatedDelivery",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "multiselect",
          id: "estates",
          label: "estates",
          optionLabel: ["name"],
          options: estates.value,
          rules: ["isRequired"],
          value: product.value.estateIds,
          variable: "estateIds",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "checkbox",
          id: "available",
          label: "available",
          invalid: false,
          rules: [],
          value: product.value.available,
          variable: "available",
          variant: company.value.primary,
        },
        {
          class: "col-span-12 text-right",
          element: "button",
          id: "submit-button",
          label: "save",
          textVariant: company.value.primaryText,
          type: "submit",
          variant: company.value.primary,
        },
      ];
    };

    const updateProduct = (data) => {
      data.productId = product.value.id;
      store.dispatch("product/updateProduct", data);
    };

    onMounted(() => {
      if (!product.value.id || product.value.id != route.params.productId) {
        store.dispatch("product/getProduct", route.params.productId);
      }
      if (estates.value.length) {
        setFormElements();
      } else {
        store.dispatch("estate/getEstates", company.value.id);
      }
    });

    watch(
      () => waiting.value,
      (waiting) => {
        if (!waiting) {
          setFormElements();
        }
      }
    );

    return {
      updateProduct,
      formElements,
      t,
    };
  },
};
</script>
